<template>
  <div class="dashboard">
    <div class="row">
      <div class="flex xs12 md6">
        <router-link :to="{ name: 'users' }">
          <va-card class="grow">
            <va-card-content>
              <p class="display-2 mb-1" :style="{color: 'primary'}">{{ users }}</p>
              <p class="no-wrap">Users</p>
            </va-card-content>
          </va-card>
        </router-link>
      </div>
      <div class="flex xs12 md6">
        <router-link :to="{ name: 'sessions' }">
          <va-card class="grow">
            <va-card-content>
              <p class="display-2 mb-1" :style="{color: 'primary'}">{{ sessions }}</p>
              <p class="no-wrap">Sessions</p>
            </va-card-content>
          </va-card>
        </router-link>
      </div>
    </div>
    <div class="row" v-if="sessionChartData && userChartData">
      <div class="flex md6 xs12">
        <va-card class="chart-widget">
          <va-card-title>
            <span>Uploaded Sessions by date</span>
            <span>Past 14 days</span>
          </va-card-title>
          <va-card-content>
            <va-chart :data="sessionChartData" type="vertical-bar"/>
          </va-card-content>
        </va-card>
      </div>
      <div class="flex md6 xs12">
      <va-card class="chart-widget">
        <va-card-title>
          <span>Uploaded Sessions by Users</span>
          <span>Past 14 days</span>
        </va-card-title>
        <va-card-content>
            <va-chart :data="userChartData" type="vertical-bar"/>
          </va-card-content>
        </va-card>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import VaChart from '@/components/va-charts/VaChart'

export default {
  name: 'dashboard',
  components: { VaChart },
  mounted: async function () {
    this.loader = this.$loading.show()
    try {
      const headers = {
        Authorization: `Bearer ${this.token}`
      }
      // Load Users
      const users = await axios.get(`${process.env.VUE_APP_API_URL}/api/users`, { headers })
      this.users = users.data.users.length

      // Load Sessions
      const sessions = await axios.get(`${process.env.VUE_APP_API_URL}/api/sessions`, { headers })
      this.sessions = sessions.data.sessions.length

      if (this.$store.state.access === 'Administrator' || this.$store.state.access === 'Manager') {
        let labels = []
        const userLabels = []
        labels.push(moment().format('YYYY-MM-DD'))

        for (let i = 1; i < 14; i++) {
          labels.push(moment().subtract(i, 'days').format('YYYY-MM-DD'))
        }
        labels = labels.reverse()

        await Promise.all(users.data.users.map((user) => {
          userLabels.push(user.email)
        }))

        // Load Sessions
        const data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        const userData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        const sessionStats = await axios.get(`${process.env.VUE_APP_API_URL}/api/stats/sessions`, { headers })
        await Promise.all(sessionStats.data.sessions.map((session) => {
          const index = labels.indexOf(session.created_at.split('T')[0])
          data[index] = data[index] + 1

          const userIndex = userLabels.indexOf(session.user)
          userData[userIndex] = userData[userIndex] + 1
        }))

        this.sessionChartData = {
          labels,
          datasets: [
            {
              label: 'Sessions',
              backgroundColor: 'blue',
              borderColor: 'transparent',
              data,
            },
          ],
        }

        this.userChartData = {
          labels: userLabels,
          datasets: [
            {
              label: 'Users',
              backgroundColor: 'blue',
              borderColor: 'transparent',
              data: userData,
            },
          ],
        }
      }
    } catch (error) {
      this.$router.push({ name: 'login', query: { redirect: '/pages/dashboard' } })
    }
    this.loader.hide()
  },
  data () {
    return {
      loader: null,
      token: this.$store.state.token,
      users: 0,
      sessions: 0,
      stats: [],
      sessionChartData: null,
      userChartData: null
    }
  },
  methods: {
  },
}
</script>

<style lang="scss">
  .row-equal .flex {
    .va-card {
      height: 100%;
    }
  }

  .dashboard {
    .va-card {
      margin-bottom: 0 !important;
      &__title {
        display: flex;
        justify-content: space-between;
      }
    }
  }

  .grow {
    transition: all .2s ease-in-out;
  }
  .grow:hover {
    transform: scale(1.05);
  }
</style>
